import React from 'react';
import './Statements.css';
import Layout from "../../Components/Layout/Layout";


const TermsOfUse = () => {
    return (
        <Layout>
        <div className="containerDeclarationsPages">
            <h1>תנאי שימוש</h1>
            <p>
                ברוכים הבאים לאתר של חברת Puma.
                בשימושכם באתר זה, אתם מסכימים לתנאים המפורטים במסמך זה.
                אם אינכם מסכימים לתנאים אלה, אנא הימנעו משימוש באתר.
            </p>
            <h2>שימוש באתר</h2>
            <p>
                האתר נועד לשימוש פרטי בלבד. אין להשתמש בתוכן האתר לצרכים מסחריים ללא אישור מראש ובכתב מחברת Puma.
            </p>
            <h2>תוכן ומידע</h2>
            <p>
                אנו עושים כל מאמץ לספק תוכן מדויק ועדכני באתר.
                עם זאת, אנו לא אחראים לטעויות או שגיאות בתוכן המוצג באתר.
            </p>
            <h2>קישורים לאתרים חיצוניים</h2>
            <p>
                האתר עשוי להכיל קישורים לאתרים חיצוניים.
                חברת Puma אינה אחראית לתוכן או לשירותים המוצעים באתרים אלו.
            </p>
            <h2>הגבלת אחריות</h2>
            <p>
                חברת Puma לא תישא באחריות לכל נזק ישיר או עקיף הנובע מהשימוש באתר או מאי היכולת להשתמש באתר.
            </p>
            <h2>שינויים בתנאים</h2>
            <p>
                חברת Puma שומרת לעצמה את הזכות לשנות את תנאי השימוש מעת לעת.
                כל שינוי ייכנס לתוקף מיד עם פרסומו באתר.
            </p>
            <h2>יצירת קשר</h2>
            <p>
                לכל שאלה או בעיה בנוגע לתנאי השימוש, אנא פנו אלינו בכתובת המייל: bwdwtz417@gmail.com
                או בטלפון: 0529022532.
            </p>
        </div>
        </Layout>
    );
};

export default TermsOfUse;
