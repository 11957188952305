import React from 'react';
import './AddToCartPopup.css';

const AddToCartPopup = ({ item, onClose, onContinueShopping, onProceedToCheckout }) => {
    return (
        <div className="popup-overlay show" onClick={onClose}>
            <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                <span className="close" onClick={onClose} role="button" tabIndex="0">&times;</span>
                <h2>המוצר התווסף בהצלחה לעגלת הקניות</h2>
                <div className="item-details">
                    <img src={item.image} alt={item.title} />
                    <div>
                        <p><strong>פריט:</strong> {item.title}</p>
                        <p><strong>גודל:</strong> {item.size}</p>
                        <p><strong>צבע:</strong> {item.color.name}</p>
                        <p><strong>סוג עץ:</strong> {item.woodType}</p>
                        <p><strong>מחיר:</strong> ₪{item.price}</p>
                    </div>
                </div>
                <div className="popup-actions">
                    <button onClick={onProceedToCheckout} className="checkout-button">מעבר לעגלת קניות</button>
                    <button onClick={onContinueShopping} className="continue-shopping-button">להמשך קניה</button>
                </div>
            </div>
        </div>
    );
};

export default AddToCartPopup;
