import React, { useState, useContext, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { CartContext } from '../../Context/CartContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import './Navbar.css';

const Navbar = ({contactUsRef}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { cart } = useContext(CartContext);

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    const closeMenu = () => setIsMenuOpen(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isMenuOpen && !event.target.closest('.navbar-container')) {
                closeMenu();
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isMenuOpen]);

    return (
        <nav className={`navbar ${isMenuOpen ? 'menu-open' : ''}`}>
            <div className="navbar-container">
                <button className="menu-toggle" onClick={toggleMenu}>
                    <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
                </button>
                <div className="logo">
                    <Link to="/" onClick={closeMenu}>פומה</Link>
                </div>
                <ul className="nav-menu">
                    <li className="nav-item">
                        <NavLink to="/" className="nav-link" activeClassName="active" onClick={closeMenu}>
                            בית
                        </NavLink>
                    </li>
                    {/*<li className="nav-item">*/}
                    {/*    <NavLink to="/admin" className="nav-link" activeClassName="active" onClick={closeMenu}>*/}
                    {/*        אדמין*/}
                    {/*    </NavLink>*/}
                    {/*</li>*/}

                    <li className="nav-item">
                        <NavLink to="/personalCustomization" className="nav-link" activeClassName="active"
                                 onClick={closeMenu}>
                            הזמנה בהתאמה אישית
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/contactUs" className="nav-link" activeClassName="active" onClick={closeMenu}>
                            בואו נדבר
                        </NavLink>
                    </li>
                </ul>
                <div className="cart-icon">
                    <Link to="/orders" className="nav-link" onClick={closeMenu}>
                        <FontAwesomeIcon icon={faShoppingCart}/>
                        <span className="cart-count">{cart.length}</span>
                    </Link>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
