import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './PergolasAndDecks.css';
import pergolaImage from '../../images/wood-pergola.jpg';
import deckImage from '../../images/wood-deck.jpg';
import fenceImage from '../../images/wood-fence.jpg';
import { CartContext } from "../../Context/CartContext";
import playHouse from "../../images/playHouse.jpeg";
import playHouse1 from "../../images/playHouse/playHouse.jpg";
import playHouse2 from "../../images/playHouse/playHouse1.jpg";
import playHouse3 from "../../images/playHouse/wood-house.jpg";
import playHouse4 from "../../images/playHouse/wood-hous-2.jpg";
import pergola from "../../images/pergola/pergola1.jpg";
import pergola1 from "../../images/pergola/pergola2.jpg";
import pergola2 from "../../images/pergola/pergola3.jpg";
import pergola3 from "../../images/pergola/pergola4.jpg";
import pergola4 from "../../images/pergola/pergola5.jpg";
import pergola5 from "../../images/pergola/pergola6.jpg";
import fence from "../../images/fence/fence.jpg";
import fence1 from "../../images/fence/fence1.jpg";
import fence2 from "../../images/fence/fence2.jpg";
import fence7 from "../../images/fence/fence3.jpg";
import fence3 from "../../images/fence/fence4.jpg";
import fence4 from "../../images/fence/fence5.jpg";
import fence5 from "../../images/fence/fence6.jpg";
import fence6 from "../../images/fence/fence7.jpg";
import deck from "../../images/deck/deck.jpg";
import deck1 from "../../images/deck/deck1.jpg";
import deck2 from "../../images/deck/deck2.jpg";
import deck3 from "../../images/deck/deck3.jpg";

const projects = [
    { id: 16, title: "בית עץ", mainImage: playHouse  ,galleryImages: [playHouse4, playHouse3,playHouse1,playHouse2], sizes: ['לבחירת לקוח'],  woodTypes: ['אורן'],price: "---" },
    { id: 17, title: "פרגולה מעץ", mainImage: pergolaImage,galleryImages: [pergola,pergola1,pergola2,pergola3,pergola4,pergola5],sizes: ['לבחירת לקוח'], woodTypes: ['אורן'],price: "---" },
    { id: 18, title: 'גדרות', mainImage: fenceImage,galleryImages: [fence,fence1,fence2,fence3,fence4,fence5,fence6,fence7],sizes: ['לבחירת לקוח'], woodTypes: ['אורן'], "price": "החל מ450" },
    { id: 20, title: 'דק', mainImage: deckImage,galleryImages: [deck, deck1, deck2,deck3], sizes: ['לבחירת לקוח'], woodTypes: ['אורן'],price: "---" },
];

export { projects };

function PergolasAndDecks() {
    const { addToItem } = useContext(CartContext);
    const navigate = useNavigate();

    const handleClick = (project) => {
        addToItem(project);
        navigate(`/product-page/${project.id}`, { state: { project } });
    };

    return (
        <div className='cards-container'>
            <h1>פרגולות ודקים</h1>
            <div className="cards-grid">
                {projects.map(project => (
                    <div className="card" key={project.id} onClick={() => handleClick(project)}>
                        <img src={project.mainImage} alt={project.title} className="card-image" />
                        <div className="card-content">
                            <h2>{project.title}</h2>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PergolasAndDecks;
