import React from 'react';
import './AboutUs.css';
import companyImage from '../../images/swing.png'; // ודא שיש לך תמונה מתאימה בתיקייה זו

function AboutUs() {
    return (
        <div className='about-us-container'>
            <div className="about-us-content">
                <div className="about-us-image">
                    <img src={companyImage} alt="תמונה של ערסל נדנדה"/>
                </div>
                <div className="about-us-text">
                <h1>אודותינו</h1>
                    <p>
                        ברוכים הבאים לPuma! אנחנו חברה שמתמחה בפתרונות ריהוט לבית ולגן,
                        עם דגש על איכות, עיצוב וחדשנות. המוצרים שלנו מיוצרים מהחומרים הטובים ביותר
                        וכוללים מגוון רחב של פרויקטים ייחודיים המותאמים לצרכי הלקוחות שלנו.
                    </p>
                    <p>
                        אנו מתחייבים להעניק שירות מעולה וחוויית קנייה בלתי נשכחת.
                        אנחנו תמיד כאן כדי לסייע לכם לבחור את הרהיטים המתאימים ביותר לביתכם או לגינתכם.
                        בואו להתרשם ממבחר הפרויקטים שלנו וליהנות מריהוט איכותי ומעוצב!
                    </p>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;
