import React, { useState } from 'react';
import './PersonalCustomization.css';
import Layout from "../../Components/Layout/Layout";

function PersonalCustomization() {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [description, setDescription] = useState('');

    const handleSubmit = () => {
        const businessWhatsAppNumber = '+972529022532'; // Replace with actual business WhatsApp number
        const businessEmail = 'bwdwtz417@gmail.com';

        const message = `
שם המזמין: ${name}
מספר טלפון: ${phone}
פרטי הבקשה: ${description}
    `;

        if (window.matchMedia("(max-width: 767px)").matches) {
            // For mobile devices, use WhatsApp
            const whatsappUrl = `https://wa.me/${businessWhatsAppNumber}?text=${encodeURIComponent(message)}`;
            window.open(whatsappUrl, '_blank');
        } else {
            // For desktop, use Gmail
            const subject = 'בקשה חדשה מהאתר';
            const gmailUrl = `https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${encodeURIComponent(businessEmail)}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`;
            window.open(gmailUrl, '_blank');
        }
        alert('בקשתך נשלחה בהצלחה! נחזור אליך בהקדם.');
    };

    return (
        <Layout>
            <div className='personal-customization-container'>
                <div className="personal-customization-main">
                    <h2 className='personal-customization-heading'>הזמנה בהתאמה אישית</h2>
                    <div className='personal-customization-form'>
                        <label tabIndex="0">
                            שם המזמין:
                            <input
                                type='text'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder='הכנס את שמך'
                                aria-label="שדה הזנת שם המזמין"
                            />
                        </label>
                        <label tabIndex="0">
                            מספר טלפון:
                            <input
                                type='text'
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                placeholder='הכנס את מספר הטלפון שלך'
                                aria-label="שדה הזנת מספר טלפון"
                            />
                        </label>
                        <label tabIndex="0">
                            פרטי הבקשה:
                            <textarea
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder='הכנס תיאור מפורט של המוצר'
                                aria-label="שדה הזנת פרטי הבקשה"
                            ></textarea>
                        </label>
                        <button className='submit-button' onClick={handleSubmit} role="button">
                            שלח לנו את פרטי הבקשה
                        </button>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default PersonalCustomization;