import React, {useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import './Offers.css';
import package1Image from '../../images/package-table-swing.jpg';
import package3Image from '../../images/package-garden.webp';
import {CartContext} from "../../Context/CartContext";
import swing5 from "../../images/swing/swing5.jpg";
import table from "../../images/table/table.jpg";
import bench1 from "../../images/bench/bench2.jpg";
import bench2 from "../../images/bench/bench1.jpg";

const projects = [
    {
        id: 21,
        title: 'חבילת פינות ישיבה',
        mainImage: package1Image,
        galleryImages: [swing5, table, bench1],
        sizes: ['2 ספסלים זוגיים, שולחן פינת ישיבה ונדנדה'],
        woodTypes: ['אורן'],
        price: "---"
    },
    {
        id: 22,
        title: 'חבילת הגינה המשתלמת',
        mainImage: package3Image,
        galleryImages: [table, bench1, bench2],
        sizes: ['ספסל זוגי, ספסל יחיד, שולחן פינת ישיבה'],
        woodTypes: ['אורן'],
        price: "---"
    }
];
export {projects};

const Offers = () => {
    const { addToItem } = useContext(CartContext);
    const navigate = useNavigate();

    const handleClick = (project) => {
        addToItem(project);
        navigate(`/product-page/${project.id}`, { state: { project } });
    };

    return (
        <div className="special-offers-container">
            <div className="special-offers-main">
                <h1>חבילות בשבילך</h1>
                <div className="offers_detelis">
                    {projects.map(project => (
                        <div className="offer-card" key={project.id} onClick={() => handleClick(project)}>
                            <img src={project.mainImage} alt={project.title} className="offer-image" />
                            <h3 className="offer-title">{project.title}</h3>
                            <p className="offer-description">בחרו את החבילה הכי משתלמת לגינה שלכם</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Offers;
