import React, { useState } from 'react';
import { Link } from "react-router-dom";
import emailjs from 'emailjs-com';
import './Footer.css';

function Footer() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        // Prepare email template parameters
        const templateParams = {
            to_name: 'Recipient Name', // Optional: can be omitted or replaced
            from_name: 'Your Website', // Optional: can be omitted or replaced
            message: `היי, אני מעוניין להצטרף לעדכונים שלכם על מבצעים. המייל שלי הוא: ${email}`,
            user_email: email
        };

        // Send email using emailjs
        emailjs.send('service_zlkbd6l', 'template_49mvlpl', templateParams, 'DKL2QLy_yiHcQ53pZ')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                setMessage('המייל נוסף בהצלחה');
                setTimeout(() => setMessage(''), 1000);
                setEmail('');
            }, (error) => {
                console.log('FAILED...', error);
                setMessage('שגיאה בשליחת המייל');
                setTimeout(() => setMessage(''), 1000);
            });
    };

    return (
        <div className='footer-container'>
            <section className='footer-subscription'>
                <p className='footer-subscription-heading'>
                    הצטרפו לניוזלטר שלנו כדי לקבל עדכונים על המבצעים הכי חמים
                </p>
                <p className='footer-subscription-text'>
                    תוכל לבטל את ההרשמה בכל עת
                </p>
                <div className='input-areas'>
                    <form onSubmit={handleSubmit}>
                        <input
                            className='footer-input'
                            name='email'
                            type='email'
                            placeholder='האימייל שלך'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <button type='submit' className='send-button'>שלח</button>
                    </form>
                    {message && <p className='success-message'>{message}</p>}
                </div>
            </section>
            <div className='footer-links'>
                <div className='footer-link-wrapper'>
                    <div className='footer-link-items'>
                        <h2>קיצורים</h2>
                        <Link to='/'>בית</Link>
                        <Link to='/orders'>עגלת קניות</Link>
                        <Link to='/contactUs'>בואו נדבר</Link>
                    </div>
                    <div className='footer-link-items'>
                        <h2>מידע</h2>
                        <Link to='/accessibility'>הצהרת נגישות</Link>
                        <Link to='/privacy'>הצהרת פרטיות</Link>
                        <Link to='/terms'>תנאי שימוש</Link>
                    </div>
                </div>
                <div className='footer-link-wrapper'>
                    <div className='footer-link-items'>
                        <h2>רשתות חברתיות</h2>
                        <Link to='https://www.instagram.com/pumawoodworking/' target="_blank">Instagram</Link>
                        <Link to='https://www.facebook.com/profile.php?id=61556785871965'
                              target="_blank">Facebook</Link>
                    </div>
                </div>
            </div>
            <section className='social-media'>
                <div className='social-media-wrap'>
                    <small className='website-rights'> Hazan © Puma 2024 </small>
                </div>
            </section>
        </div>
    );
}

export default Footer;
