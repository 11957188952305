import React, { useState, useContext } from 'react';
import './ProductPage.css';
import { CartContext } from '../../Context/CartContext';
import { useNavigate, useLocation } from "react-router-dom";
import AddToCartPopup from '../../Components/AddToCartPopup/AddToCartPopup';

const ProductPage = () => {
    const { state } = useLocation();
    const { project } = state;
    const { addToCart } = useContext(CartContext);

    const [selectedSize, setSelectedSize] = useState(project.sizes[0]);
    const [selectedColor, setSelectedColor] = useState('ללא');
    const [selectedWoodType, setSelectedWoodType] = useState(project.woodTypes[0]);
    const [mainImg, setMainImg] = useState(project.mainImage);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);
    const [popupVisible, setPopupVisible] = useState(false);
    const [addedItem, setAddedItem] = useState(null);
    const navigate = useNavigate();
    const colors = [
        { name: 'ללא', code: '#dfdede' },
        { name: 'לבן', code: '#fff' },
        { name: 'אגוז', code: '#9a6543' },
        { name: 'אלון', code: '#7b5136' }
    ];

    const handleSizeChange = (e) => {
        setSelectedSize(e.target.value);
    };

    const handleColorChange = (color) => {
        setSelectedColor(color.name);
    };

    const handleWoodTypeChange = (e) => {
        setSelectedWoodType(e.target.value);
    };

    const calculatePrice = () => {
        let price = project.basePrice;
        return price;
    };

    const handleAddToCart = () => {
        const newItem = {
            id: `${project.title}-${selectedSize}-${selectedColor}-${selectedWoodType}`, // Unique ID for each item
            title: project.title,
            size: selectedSize,
            color: colors.find(c => c.name === selectedColor) || { name: 'ללא', code: 'none' }, // Ensure color is valid
            woodType: selectedWoodType,
            price: calculatePrice(),
            image: mainImg,
        };
        addToCart(newItem);
        setAddedItem(newItem);
        setShowSuccessMessage(true);
        setTimeout(() => {
            setShowSuccessMessage(false);
            setPopupVisible(true);
        }, 1000);
    };

    const goBack = () => {
        navigate(-1);
    };

    const openModal = (image) => {
        setCurrentImage(image);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setCurrentImage(null);
    };

    const handleClosePopup = () => {
        setPopupVisible(false);
    };

    const handleContinueShopping = () => {
        setPopupVisible(false);
    };

    const handleProceedToCheckout = () => {
        setPopupVisible(false);
        navigate('/orders');
    };

    return (
        <div className='product-page'>
            <h1>{project.title}</h1>
            <div className='product-details'>
                <div className='image-gallery'>
                    <img
                        className='main-image'
                        src={mainImg}
                        alt={project.title}
                        onClick={() => openModal(mainImg)}
                        role="button"
                        tabIndex="0"
                    />
                    <div className='thumbnail-gallery'>
                        {project.galleryImages.map((image, index) => (
                            <img
                                key={index}
                                className='thumbnail'
                                src={image}
                                alt={`${project.title} ${index + 1}`}
                                onClick={() => setMainImg(image)}
                                role="button"
                                tabIndex="0"
                            />
                        ))}
                    </div>
                </div>

                <div className='selectors'>
                    <label>
                        גודל:
                        <select value={selectedSize} onChange={handleSizeChange}>
                            {project.sizes.map((size, index) => (
                                <option key={index} value={size}>{size}</option>
                            ))}
                        </select>
                    </label>
                    <label>
                        צבע:
                        <div className='color-picker'>
                            {colors.map((color, index) => (
                                <div
                                    key={index}
                                    className={`color-swatch ${selectedColor === color.name ? 'selected' : ''}`}
                                    style={color.code === 'none' ? {} : { backgroundColor: color.code }}
                                    onClick={() => handleColorChange(color)}
                                    role="button"
                                    tabIndex="0"
                                >
                                    {color.code === 'none' && <span className='color-name'>&times;</span>}
                                    <span className='color-name'>{color.name}</span>
                                </div>
                            ))}
                        </div>
                    </label>
                    <label>
                        סוג עץ:
                        <select value={selectedWoodType} onChange={handleWoodTypeChange}>
                            {project.woodTypes.map((woodType, index) => (
                                <option key={index} value={woodType}>{woodType}</option>
                            ))}
                        </select>
                    </label>
                    <p className='price-display'>מחיר: ₪{project.price}</p>
                    <button className='add-to-cart-button' onClick={handleAddToCart} aria-label="הוסף לסל">
                        הוסף לסל
                    </button>
                    {showSuccessMessage && <p className='success-message' aria-live="polite">הפריט נוסף לסל!</p>}
                    <p className='vat-note'> *כל המחירים הנקובים לא כוללים מע"מ וצביעה</p>
                    <div className='back-link' onClick={goBack} role="button" tabIndex="0">
                        ➤ חזרה לבחירת המוצרים
                    </div>
                </div>
            </div>
            {modalOpen && (
                <div className="modal" onClick={closeModal} role="button" tabIndex="0">
                    <span className="close" onClick={closeModal} role="button" tabIndex="0">
                        &times;
                    </span>
                    <img className="modal-content" src={currentImage} alt="Modal" />
                </div>
            )}
            {popupVisible && (
                <AddToCartPopup
                    item={addedItem}
                    onClose={handleClosePopup}
                    onContinueShopping={handleContinueShopping}
                    onProceedToCheckout={handleProceedToCheckout}
                />
            )}
        </div>
    );
};

export default ProductPage;
