import React from 'react';
import './Statements.css';
import Layout from "../../Components/Layout/Layout";

const PrivacyPolicy = () => {
    return (
<Layout>
        <div className="containerDeclarationsPages">
            <h1>הצהרת פרטיות</h1>
            <p>
                אנו בחברת Puma מכבדים את פרטיותכם ומתחייבים להגן על המידע האישי שלכם.
                מסמך זה מתאר כיצד אנו אוספים, משתמשים ושומרים על המידע האישי שלכם בעת השימוש באתרנו.
            </p>
            <h2>איסוף מידע</h2>
            <p>
                אנו אוספים מידע אישי שהנכם מספקים לנו בעת הרשמה, ביצוע רכישות, ומילוי טפסים באתר.
                מידע זה עשוי לכלול שם, כתובת דוא"ל, כתובת למשלוח, ומספר טלפון.
            </p>
            <h2>שימוש במידע</h2>
            <p>
                אנו משתמשים במידע שנאסף לצורך:
                <ul>
                    <li>עיבוד והשלמת הזמנות.</li>
                    <li>שיפור שירות הלקוחות.</li>
                    <li>שליחת עדכונים ופרסומים במייל.</li>
                </ul>
            </p>
            <h2>שמירה על המידע</h2>
            <p>
                אנו נוקטים באמצעי אבטחה מתקדמים לשמירה על המידע האישי שלכם מפני גישה בלתי מורשית,
                שינוי, חשיפה או השמדה.
            </p>
            <h2>פנייה בנושא פרטיות</h2>
            <p>
                במידה ויש לכם שאלות או בקשות בנוגע למדיניות הפרטיות שלנו, אנא פנו אלינו בכתובת המייל: bwdwtz417@gmail.com
                או בטלפון: 0529022532.
            </p>
        </div>
</Layout>
    );
};

export default PrivacyPolicy;
