import React, { useContext } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import './GardenFurniture.css';
import benchImage from '../../images/bench.jpeg';
import tableImage from '../../images/table.jpeg';
import swingImage from '../../images/wood-swing.jpg';
import bench from '../../images/bench/bench.jpg';
import bench1 from '../../images/bench/bench2.jpg';
import bench2 from '../../images/bench/bench1.jpg';
import table from '../../images/table/table.jpg';
import table1 from '../../images/table/table2.jpg';
import table2 from '../../images/table/table3.jpg';
import table3 from '../../images/table/table4.jpg';
import table4 from '../../images/table/table5.jpg';
import table5 from '../../images/table/table7.jpg';
import swing from '../../images/swing/swing.jpg';
import swing1 from '../../images/swing/swing1.jpg';
import swing2 from '../../images/swing/swing2.jpg';
import swing3 from '../../images/swing/swing3.jpg';
import swing4 from '../../images/swing/swing4.jpg';
import swing5 from '../../images/swing/swing5.jpg';
import woodHolder from '../../images/wood-holder/wood-holder.jpg';
import woodHolde1 from '../../images/wood-holder/wood-holder1.jpg';
import gardenFurnitureImage from '../../images/garden-Furniture.jpg';
import seatingArea from '../../images/seatingArea/seatingArea.jpg';
import seatingArea1 from '../../images/seatingArea/seatingArea1.jpg';
import seatingArea2 from '../../images/seatingArea/pinat.jpg';
import { CartContext } from "../../Context/CartContext";


const projects = [
    { id: 10, title: "ספסל גינה", mainImage: benchImage  ,galleryImages: [bench,bench1,bench2],  sizes: ['60X45, גובה: 90 ', '120X45, גובה: 90', '180X45, גובה: 90'], woodTypes: ['אורן'],price: "החל מ300" },
    { id: 11, title: "שולחנות", mainImage: tableImage  ,galleryImages: [table,table1,table2,table3,table4,table5], sizes: ['180X120, שולחן כבד: גובה: 90','שולחן קק"ל: 165X160, גובה 80'],  woodTypes: ['אורן'],price: "החל מ900" },
    { id: 12, title: "נדנדות גן", mainImage: swingImage  ,galleryImages: [swing,swing1,swing2,swing3,swing4,swing5],  sizes: ['180X160, גובה: 200','נדנדה עם גגון: 180X160, גובה: 200 '],  woodTypes: ['אורן'],price: "החל מ1750" },
    { id: 13, title: "פינות ישיבה", mainImage: gardenFurnitureImage  ,galleryImages: [seatingArea2, seatingArea1,seatingArea],   sizes: ['ללא משענת: 120X28, גובה: 45','שולחן פנות ישיבה: 100X60, גובה 45','ללא משענת: 180X28, גובה: 45'], woodTypes: ['אורן'],price: "החל מ350" },
    { id: 15, title: "מחזיק עצים גינה", mainImage: woodHolde1  ,galleryImages: [woodHolder], sizes: ['100X45, גובה: 90'],  woodTypes: ['אורן'],price: "600" }

];
export { projects };
function GardenFurniture() {
        const { addToItem } = useContext(CartContext);
    const navigate = useNavigate();

    const handleClick = (project) => {
        addToItem(project);
        navigate(`/product-page/${project.id}`, { state: { project } });
    };

    return (
        <div className='cards-container'>
            <h1>ריהוט גינה</h1>
            <div className="cards-grid">
                {projects.map(project => (
                    <div className="card" key={project.id} onClick={() => handleClick(project)}>
                        <img src={project.mainImage} alt={project.title} className="card-image"/>
                        <div className="card-content">
                            <h2>{project.title}</h2>
                        </div>
                    </div>
                ))}
            </div>

        </div>
    );
}

export default GardenFurniture;