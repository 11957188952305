import React from 'react';
import './Statements.css';
import Layout from "../../Components/Layout/Layout";


const AccessibilityStatement = () => {
    return (
        <Layout>
        <div className="containerDeclarationsPages">
            <h1>הצהרת נגישות</h1>
            <p>
                חברת Puma מחויבת להנגשת האתר לכלל האוכלוסייה, כולל אנשים עם מוגבלויות.
                אנו פועלים לשיפור מתמיד של נגישות האתר במטרה להבטיח חווית שימוש נוחה וזמינה לכל לקוח.
            </p>
            <h2>פעולות שבוצעו להנגשת האתר</h2>
            <ul>
                <li>התאמות ניווט במקלדת.</li>
                <li>התאמות צבעים וניגודיות לטובת אנשים עם לקויות ראייה.</li>
                <li>הוספת תגיות ALT לתמונות.</li>
                <li>שימוש בכותרות מבניות לניווט נוח.</li>
            </ul>
            <h2>פנייה בנושא נגישות</h2>
            <p>
                במידה ונתקלתם בקושי בנגישות האתר או יש לכם הצעות לשיפור, אנא פנו אלינו בכתובת המייל: bwdwtz417@gmail.com
                או בטלפון: 0529022532.
            </p>
            <p>אנו מתחייבים לטפל בפניותיכם במהירות האפשרית ולספק מענה הולם.</p>
        </div>
        </Layout>
    );
};

export default AccessibilityStatement;
