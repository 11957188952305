import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './WhatsAppFloatingButton.css';

const WhatsAppFloatingButton = () => {
    const whatsappNumber = '+972529022532'; // הגדר את מספר הטלפון של העסק שלך
    const prefilledMessage = 'שלום, אני מעוניין ב';

    const openWhatsApp = () => {
        const url = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(prefilledMessage)}`;
        window.open(url, '_blank');
    };

    return (
        <div className="whatsapp-floating-button" onClick={openWhatsApp}>
            <FontAwesomeIcon icon={faWhatsapp} />
        </div>
    );
};

export default WhatsAppFloatingButton;