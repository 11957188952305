import React from 'react';
import './Backgrounds.css';

function BgPic1() {

    return (
        <div className="bg2-container">

        </div>
    );
}

export default BgPic1;