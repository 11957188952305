import React, { useContext } from 'react';
import './HomeFurniture.css';
import decorativeBench from '../../images/decorative-Bench/bench4.jpg';
import decorativeBench1 from '../../images/decorative-Bench/bench2.jpg';
import decorativeBench2 from '../../images/decorative-Bench/bench3.jpg';
import homeTable from '../../images/table/table5.jpg';
import homeTable1 from '../../images/table/table1.jpg';
import tableImage from '../../images/home-table.jpg';
import bedImage from '../../images/bed/beds.jpeg';
import bedImage1 from '../../images/bed/bed2.jpg';
import bedImage2 from '../../images/bed/bed1.jpg';
import bedImage3 from '../../images/bed/bed6.jpg';
import bedImage4 from '../../images/bed/bed3.jpg';
import Cabinet from '../../images/cabinet/Cabinet.jpg';
import Cabinet1 from '../../images/cabinet/Cabinet1.jpg';
import shelvesImage from '../../images/home-shelves.jpg';
import shelves from '../../images/shelves.jpeg';
import shelves1 from '../../images/cabinet/shelves.jpg';
import shelves2 from '../../images/cabinet/shelves1.jpg';
import shelves3 from '../../images/cabinet/shelves2.jpg';
import shelves4 from '../../images/cabinet/shelves4.jpg';
import shelves5 from '../../images/cabinet/shelves5.jpg';
import shelves6 from '../../images/cabinet/shelves6.jpg';
import rack from '../../images/rack.jpeg';
import rack1 from '../../images/types/rack.jpg';
import wallCovering from '../../images/wall-covering/wallCovering2.jpg';
import wallCovering1 from '../../images/wall-covering/wall-covering.jpg';
import wallCovering2 from '../../images/wall-covering/wall-covering1.jpg';
import woodHolder from '../../images/wood-holder/wood-holder.jpg';
import woodHolder1 from '../../images/wood-holder/wood-holder1.jpg';
import dresser from '../../images/types/dresser.jpg';
import { CartContext } from "../../Context/CartContext";
import {Link, useNavigate} from "react-router-dom";

const projects = [
    { id: 1, title: "ספסל איקס דקורטיבי", mainImage: decorativeBench2, galleryImages: [decorativeBench,decorativeBench2,decorativeBench1], sizes: ['120X28, גובה: 45'], woodTypes: ['אורן'],price: "החל מ400" },
    { id: 2, title: "שולחנות", mainImage: tableImage, galleryImages: [homeTable,homeTable1], sizes: [' שולחן ארוך-צר: 120X40, גובה 45','שולחן קפה: 80X50, גובה: 45',], woodTypes: ['אורן'],price: "החל מ300" },
    { id: 3, title: "מיטות", mainImage: bedImage, galleryImages: [bedImage2,bedImage1,bedImage3,bedImage4], sizes: ['מיטה בסיסית: גובה: 90, 200X80','מיטה נשלפת: גובה: 20, 190X80','מיטה מפנקת: גובה: 110, 200X80'], woodTypes: ['אורן'],price: "החל מ400" },
    { id: 4, title: "ארונות", mainImage: shelvesImage, galleryImages: [Cabinet,Cabinet1], sizes: ['לבחירת הלקוח'], woodTypes: ['אורן'],price: "---" },
    { id: 5, title: "מדפים", mainImage: shelves, galleryImages: [shelves1,shelves2,shelves3,shelves4,shelves5,shelves6], sizes: ['מדף קטן: 60X28', 'מדף בינוני: 90X28', 'מדף גדול: 180X28','מדף פינה:50X20','מדף תלוי: 95X15'], woodTypes: ['אורן'],price: "החל מ350" },
    { id: 6, title: "מתלה מעילים", mainImage: rack, galleryImages: [rack1], sizes: ['90X15'], woodTypes: ['אורן'],price: "החל מ100" },
    { id: 7, title: "חיפוי קירות", mainImage: wallCovering, galleryImages: [wallCovering,wallCovering1,wallCovering2], sizes: ['לבחירת לקוח'], woodTypes: ['אורן'],price:"החל מ165" },
    { id: 8, title: "מחזיק עצים ביתי", mainImage: woodHolder1, galleryImages: [woodHolder1,woodHolder], sizes: ['100X45, גובה: 100 '], woodTypes: ['אורן'],price: "החל מ550" },
    { id: 9, title: "שידות", mainImage: dresser, galleryImages: [dresser], sizes: ['120X45, גובה: 70'], woodTypes: ['אורן'],price: "החל מ1300" },
];
export { projects };

function HomeFurniture() {
    const { addToItem } = useContext(CartContext);
    const navigate = useNavigate();

    const handleClick = (project) => {
        addToItem(project);
        navigate(`/product-page/${project.id}`, { state: { project } });
    };

    return (
        <div className='cards-container'>
            <h1>ריהוט ביתי</h1>
            <div className="cards-grid">
                {projects.map(project => (
                    <div className="card" key={project.id} onClick={() => handleClick(project)}>
                        <img src={project.mainImage} alt={project.title} className="card-image"/>
                        <div className="card-content">
                            <h2>{project.title}</h2>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default HomeFurniture;