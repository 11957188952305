import React, { useState } from 'react';
import './SearchBar.css';

import { projects as homeFurnitureProjects } from '../../Components/HomeFurniture/HomeFurniture';
import { projects as gardenFurnitureProjects } from '../../Components/GardenFurniture/GardenFurniture';
import { projects as pergolasAndDecksProjects } from  '../../Components/PergolasAndDecks/PergolasAndDecks';

function SearchBar({ homeFurnitureRef, gardenFurnitureRef, pergolasAndDecksRef, ourProjectsRef }) {
    const [searchTerm, setSearchTerm] = useState('');

    const searchResults = [
        ...homeFurnitureProjects.map(project => ({ text: project.title, ref: homeFurnitureRef })),
        ...gardenFurnitureProjects.map(project => ({ text: project.title, ref: gardenFurnitureRef })),
        ...pergolasAndDecksProjects.map(project => ({ text: project.title, ref: pergolasAndDecksRef })),
        { text: 'ריהוט בית', ref: homeFurnitureRef },
        { text: 'ריהוט גן', ref: gardenFurnitureRef },
        { text: 'פרגולות ודקים', ref: pergolasAndDecksRef },
        { text: 'פרויקטים שלנו', ref: ourProjectsRef },
    ];

    const handleSearch = (event) => {
        const term = event.target.value.toLowerCase();
        setSearchTerm(term);
    };

    const handleResultClick = (result) => {
        result.ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="search-container">
            <input
                type="search"
                placeholder="מה הפרויקט שאתה מחפש?"
                className="search-input"
                value={searchTerm}
                onChange={handleSearch}
            />
            {searchTerm && (
                <ul>
                    {searchResults
                        .filter((result) => result.text.toLowerCase().startsWith(searchTerm))
                        .map((result, index) => (
                            <div className="li-results" key={index} onClick={() => handleResultClick(result)}>{result.text}</div>
                        ))}
                </ul>
            )}
        </div>
    );
}

export default SearchBar;