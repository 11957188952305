import React, {createContext, useState, useLayoutEffect} from 'react';

export const CartContext = createContext(null);

export const CartProvider = (props) => {
    const [cart, setCart] = useState([]);
    const [item, setItem] = useState({});
    // Load cart from localStorage when the component mounts
    useLayoutEffect(() => {
        const storedCart = localStorage.getItem('cart');
        if (storedCart) {
            setCart(JSON.parse(storedCart));
        }
    }, []);

    const addToCart = (item) => {
        const newCart = [...cart, item];
        setCart(newCart);
        localStorage.setItem('cart', JSON.stringify(newCart));
    };

    const removeFromCart = (itemId) => {
        const newCart = cart.filter(item => item.id !== itemId);
        localStorage.setItem('cart', JSON.stringify(newCart));
        setCart(newCart);
    };
    const addToItem = (item) => {
        setItem(item);
    };

    return (
        <CartContext.Provider value={{ cart, addToCart, removeFromCart,addToItem,item }}>
            {props.children}
        </CartContext.Provider>
    );
};
