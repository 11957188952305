import React from 'react';
import { Link } from 'react-router-dom';
import './HeroSection.css';
import swingImage from '../../images/home-furniture.png';
import tableImage from '../../images/swing.png';
import pergolahImage from '../../images/pergola.png';
import shidaImage from '../../images/table.png';
import logo from "../../images/logo4.png";
import SearchBar from "../SearchBar/SearchBar";

function HeroSection({ scrollToSection, PergolasAndDecksRef, cardsRef, middelRef, ourProjects }) {

    return (
        <div className="hero-container">
            <div className="main-container">
                <div className="logo-container">
                    <Link to="/" className="navbar-logo">
                        <img src={logo} alt="לוגו" />
                    </Link>
                </div>
                <div className="search-container">
                    <SearchBar
                        homeFurnitureRef={cardsRef}
                        gardenFurnitureRef={middelRef}
                        pergolasAndDecksRef={PergolasAndDecksRef}
                        ourProjectsRef={ourProjects}
                    />
                </div>
                <h2 className="quick-choice-title">:בחירות למהירי החלטה</h2>
                <div className="project-cards-container">
                    <div className="project-card" onClick={() => scrollToSection(PergolasAndDecksRef)} role="button" tabIndex="0" aria-label="לחץ לניווט לפרגולות ודקים">
                        <img src={pergolahImage} alt="פרגולות ודקים" className="hero-project-image" />
                        <h3 className="project-title">פרגולות ודקים</h3>
                    </div>
                    <div className="project-card" onClick={() => scrollToSection(cardsRef)} role="button" tabIndex="0" aria-label="לחץ לניווט לריהוט בית">
                        <img src={swingImage} alt="ריהוט בית" className="hero-project-image" />
                        <h3 className="project-title">ריהוט בית</h3>
                    </div>
                    <div className="project-card" onClick={() => scrollToSection(middelRef)} role="button" tabIndex="0" aria-label="לחץ לניווט לריהוט גן">
                        <img src={tableImage} alt="ריהוט גן" className="hero-project-image" />
                        <h3 className="project-title">ריהוט גן</h3>
                    </div>
                    <div className="project-card" onClick={() => scrollToSection(ourProjects)} role="button" tabIndex="0" aria-label="לחץ לניווט לפרויקטים שלנו">
                        <img src={shidaImage} alt="פרויקטים שלנו" className="hero-project-image" />
                        <h3 className="project-title">פרויקטים שלנו</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeroSection;