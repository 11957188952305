import React, { useContext } from 'react';
import './Orders.css';
import { CartContext } from '../../Context/CartContext';
import { Link, useNavigate } from 'react-router-dom';

function Orders() {
    const { cart, removeFromCart } = useContext(CartContext);
    const navigate = useNavigate();

    const handleRemove = (itemId) => {
        removeFromCart(itemId);
    };

    const goBack = () => {
        navigate(-1);
    };

    const sendOrder = () => {
        const businessWhatsAppNumber = '+972529022532';
        const businessEmail = 'bwdwtz417@gmail.com';

        const orderDetails = cart.map((item) => {
            return `
תיאור: ${item.title}
גודל: ${item.size}
צבע: ${item.color.name}
סוג עץ: ${item.woodType}

    `;
        }).join('\n\n');

        const orderSummary = `
כמות הפריטים בעגלה: ${cart.length}

        `;

        const message = `הזמנה חדשה:\n\n${orderDetails}\n\n${orderSummary}`;

        if (window.matchMedia("(max-width: 767px)").matches) {
            const whatsappUrl = `https://wa.me/${businessWhatsAppNumber}?text=${encodeURIComponent(message)}`;
            window.open(whatsappUrl, '_blank');
        } else {
            const gmailUrl = `https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${businessEmail}&su=הזמנה חדשה&body=${encodeURIComponent(message)}`;
            window.open(gmailUrl, '_blank');
        }
    };

    return (
        <div className='orders-container'>
            <h1 className='orders-heading'>ההזמנות שלך</h1>
            {cart.length === 0 ? (
                <div className='empty-cart' aria-live="polite">
                    <p>העגלה שלך ריקה</p>
                    <Link to='/' className='continue-shopping' role="button" tabIndex="0">
                        המשך ברכישה
                    </Link>
                </div>
            ) : (
                <>
                    <div className='back-link' onClick={goBack} role="button" tabIndex="0">
                        ➤ חזרה לבחירת המוצרים
                    </div>
                    <div className='orders-grid'>
                        {cart.map((item, index) => (
                            <div key={index} className='order-item'>
                                <img src={item.image} alt={`${item.title}`} className='order-item-image'/>
                                <div className='order-item-details'>
                                    <h2>{item.title}</h2>
                                    <p>גודל: {item.size}</p>
                                    <p>צבע: <span className='color-display'
                                                  style={{backgroundColor: item.color?.code || 'transparent'}}
                                                  aria-label={`צבע: ${item.color?.name || 'ללא'}`}></span> {item.color?.name || 'ללא'}
                                    </p>
                                    <p>סוג עץ: {item.woodType}</p>
                                    <button className='remove-button' onClick={() => handleRemove(item.id)} role="button" tabIndex="0" aria-label={`הסר ${item.title}`}>הסר</button>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='order-summary'>
                        <h2>סיכום הזמנה:</h2>
                        <p>כמות הפריטים בעגלה: {cart.length}</p>
                        <p>בלחיצה על "שלח הזמנה" מיד תעבור להתכתבות עם בית העסק ולהצעת מחיר. </p>
                        <div className='summary-actions'>
                            <button className='checkout-button' onClick={sendOrder} tabIndex="0">שלח הזמנה</button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default Orders;