import React, { useState } from 'react';
import Slider from 'react-slick';
import './OurProjects.css';
import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

import gardenPlanters from '../../images/garden-planters/garden-planters.jpg';
import gardenPlanters1 from '../../images/garden-planters/garden-planters1.jpg';
import gardenPlanters6 from '../../images/garden-planters/garden-planters3.jpg';
import gardenPlanters8 from '../../images/types/storge-box.jpg';
import gardenPlanters7 from '../../images/types/storge-box1.jpg';
import { Link } from "react-router-dom";
import playHouse2 from "../../images/playHouse/playHouse1.jpg";


const OurProjects = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);

    const images = [
        gardenPlanters,
        gardenPlanters1,
        gardenPlanters6,
        gardenPlanters8,
        gardenPlanters7,
        playHouse2,
    ];

    const titles = [
        "אדניות לגינה",
        "אדניות לגינה",
        "גילוף בעיצוב אישי",
        "ארגז איחסון",
        "ארגז איחסון",
        "בית עץ"
    ];

    const settings = {
        customPaging: function (i) {
            return (
                <Link to="/">
                    <img src={images[i]} alt={`Thumbnail ${i + 1}`} />
                </Link>
            );
        },
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const openModal = (image) => {
        setCurrentImage(image);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setCurrentImage(null);
    };

    return (
        <div className="ourProjects-container">
            <h1>הפרויקטים שלנו</h1>
            <div className="ourProjects-main">
                <Slider {...settings}>
                    {images.map((image, index) => (
                        <div key={index} className="project-slide" onClick={() => openModal(image)}>
                            <img src={image} alt={`Project ${index + 1}`} />
                            <div className="project-title-our">{titles[index]}</div>
                        </div>
                    ))}
                </Slider>
            </div>
            {modalOpen && (
                <div className="modal" onClick={closeModal}>
                    <span className="close">&times;</span>
                    <img className="modal-content" src={currentImage} alt="Modal" />
                </div>
            )}
        </div>
    );
};

export default OurProjects;
