import React from 'react';
import './ContactUs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Layout from "../Layout/Layout";

function ContactUs() {

    const whatsappNumber = '+972529022532';
    const prefilledMessage = 'שלום, אני מעוניין ב';
    const email = 'bwdwtz417@gmail.com'; // הגדר את כתובת המייל של העסק שלך
    const emailSubject = 'פנייה מהאתר'; // הנושא של המייל
    const emailBody = 'שלום, אני מעוניין ב'; // התוכן המוכן מראש של המייל
    const phoneNumber = '052-902-2532'; // הגדר את מספר הטלפון של העסק שלך

    const openWhatsApp = () => {
        const url = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(prefilledMessage)}`;
        window.open(url, '_blank');
    };

    const openEmail = () => {
        const url = `https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${encodeURIComponent(email)}&su=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
        window.open(url, '_blank');
    };

    const openPhone = () => {
        const url = `tel:${phoneNumber}`;
        window.open(url, '_blank');
    };

    return (
        <Layout>
            <div className="contact-us-container">
                <h1 className="contact-heading">דברו איתנו</h1>
                <div className="contact-options">
                    <div className="contact-option" onClick={openWhatsApp} role="button" tabIndex="0">
                        <FontAwesomeIcon icon={faWhatsapp} className="contact-icon" aria-label="פתיחת WhatsApp" />
                        <h3>WhatsApp</h3>
                        <p>לחצו, ומיד תעברו לשוחח איתנו</p>
                    </div>
                    <div className="contact-option" onClick={openPhone} role="button" tabIndex="0">
                        <FontAwesomeIcon icon={faPhone} className="contact-icon" aria-label="התקשרות טלפונית" />
                        <h3>Phone</h3>
                        <p>{phoneNumber}</p>
                        <p>צוריאל</p>
                    </div>
                    <div className="contact-option" onClick={openEmail} role="button" tabIndex="0">
                        <FontAwesomeIcon icon={faEnvelope} className="contact-icon" aria-label="פתיחת דואר אלקטרוני" />
                        <h3>Email</h3>
                        <p>{email}</p>
                    </div>
                </div>
                <div className="social-links">
                    <h2>Follow Us</h2>
                    <div className="social-icons">
                        <Link to="https://www.facebook.com/profile.php?id=61556785871965" target="_blank" rel="noopener noreferrer" aria-label="פייסבוק">
                            <FontAwesomeIcon icon={faFacebook} className="social-icon" />
                        </Link>
                        <Link to="https://www.instagram.com/pumawoodworking/" target="_blank" rel="noopener noreferrer" aria-label="אינסטגרם">
                            <FontAwesomeIcon icon={faInstagram} className="social-icon" />
                        </Link>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default ContactUs;